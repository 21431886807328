const Menu = [
    { header: 'Control panel' },
    /*{
        name: 'dashboard',
        title: 'Pregled',
        icon: 'mdi-view-dashboard',
        path: '/pregled',
        group: 'dashboard'
    },
    */
    /*{
        name: 'settings',
        title: 'Nastavitve',
        icon: 'mdi-cogs',
        path: '/nastavitve',
        group: 'settings'
    },*/

    {
        name: 'invoices',
        title: 'Invoices',
        icon: 'mdi-receipt',
        path: '/invoices',
        group: 'invoices'
    },

    {
        name: 'telemetry',
        title: 'Telemetry',
        icon: 'mdi-memory',
        path: '/telemetry',
        group: 'telemetry'
    },
    
    
    {
        name: 'exports',
        title: 'Exports',
        icon: 'mdi-application-export',
        path: 'data-exports',
        group: 'exports'
    },
]

export { Menu }