import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/auth/Login.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/logout',
        name: 'logout',
        meta: { permissions: [] },
        component: () => import('@/views/auth/Logout.vue')
    },
    {
        path: '/ponastavitev-gesla',
        name: 'passwordResetRequest',
        component: () => import('@/views/auth/PasswordResetRequest.vue')
    },
    {
        //path: '/ponastavitev-gesla/:token',
        path: '/password-reset/:token',
        name: 'passwordReset',
        component: () => import('@/views/auth/PasswordReset.vue')
    }, 
    /* ERRORS */
    {
        path: '*',
        name: '404',
        component: () => import('@/views/errors/404.vue')
    },
    {
        path: '/403',
        name: '403',
        component: () => import('@/views/errors/403.vue')
    },
    {
        path: '/500',
        name: '500',
        component: () => import('@/views/errors/500.vue')
    },

    {
        path: '/',
        component: () => import('@/views/RootView.vue'),
        children: [
            /*
            {
                path: 'pregled',
                name: 'dashboard' ,
                component: () => import('@/views/Dashboard.vue'),
                alias: '',
                meta: { requiresAuth: true}
            },
            {
                path: 'nastavitve',
                name: 'settings',
                component: () => import('@/views/Settings.vue'),
                meta: { requiresAuth: true }
            },*/

            {
                path: 'invoices',
                name: 'invoices' ,
                component: () => import('@/views/Invoices.vue'),
                alias: '',
                meta: { requiresAuth: true}
            },

            {
                path: 'telemetry',
                name: 'telemetry' ,
                component: () => import('@/views/Telemetry.vue'),
                alias: '',
                meta: { requiresAuth: true}
            },
            
            {
                path:'data-exports',
                name: 'exports',
                component: () => import('@/views/Exports.vue'),
                meta: { requiresAuth: true, permissions: [] }
            }
        ]
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: function(to, from, savedPosition) {
        return savedPosition || { x: 0, y: 0 }
    },
    linkActiveClass: 'v-list__tile--active router-link-active active',
    routes
})

export default router