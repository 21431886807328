import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'


import AppPageHeader from '@/views/layout/AppPageHeader.vue'
import AppLoader from '@/components/Loader.vue'
import './registerServiceWorker'
import JsonViewer from 'vue-json-viewer'
import 'vue-json-viewer/style.css'
import * as VueGoogleMaps from 'vue2-google-maps'



Vue.component('app-page-header', AppPageHeader)
Vue.component('app-loader', AppLoader)
Vue.use(PerfectScrollbar)
Vue.use(JsonViewer)

Vue.use(VueGoogleMaps, {
  load: {
    //key: 'AIzaSyD5UyIVZC25esg45RrCVg0w5kfdScDN4OI',
    key: 'AIzaSyCzbYj5sgCctZ9UVTs4ImQWbvpc7-xkMEI',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})



// Some middleware to help us ensure the user is authenticated.
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth) && (!store.getters.user.access_token || store.getters.user.access_token === '')) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  } else if ( (to.name == 'login' || to.name == 'passwordResetRequest' || to.name == 'passwordReset') && store.state.user.access_token) {
    return router.push({
      name: 'invoices'
    })
  } else if(to.path == '/') {
    return router.push({
      name: 'invoices'
    })
  } else {
      return next()
  }
})

// Check local storage to handle refreshes
if (window.localStorage) {
  var user = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_KEY)) || null
  if ( (user && store.state.user.id !== user.id) && (user.access_token && store.state.user.access_token !== user.access_token) ) {
    store.commit('SET_USER', user)
  }
}

//event bus
export const EventBus = new Vue();

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
