export default {
    app: {
        drawerTitle: process.env.VUE_APP_DRAWER_TITLE,
        title: process.env.VUE_APP_TITLE,
        shortTitle: process.env.VUE_APP_SHORT_TITLE,
        icon: 'language',
        appKey: process.env.VUE_APP_KEY
    },

    user: {
        id: null,
        name: null,
        email: null,
        role: null,
        permissions: null,
        access_token: null,
        last_seen: null,
        table: []
    },

    drawer: null,
    loader: false,
    progress: false,
    loaderText: '',
    toolbarTitle: '',
    search: '',
    searchFieldState: false,
    mobileSearch: false,

    snackbar: {
        display: false,
        y: 'bottom',
        x: 'left',
        mode: '',
        timeout: 7000,
        text: 'Hello, I\'m a snackbar'
    },

    access_log: [],
    currentRoute: null,

    table: [],
    stanja: [],
    nacini: []
}